export const en = {
  all: {
    home: {
      compensation: {
        total: 'Total',
        claimed: 'Claimed',
      },
      community: {
        title: 'Join our crypto space crew!',
        subtitle: 'Take part in the journey',
      },
    },
    routes: {
      privacy_policy: 'Privacy Policy',
      risk_advice: 'Terms of Use',
      docs: 'Docs',
      powered: 'Powered by TerraCVita',
    },
    common: {
      history: 'Launchpad History',
      rocket: 'Rocket',
      token: 'Token',
      connect: 'Connect Wallet',
      trade: 'Trade',
      swap: 'Swap',
      liquidity: 'Liquidity',
      farm: 'Farm',
      farming: 'Farming',
      staking: 'Staking',
      stake: 'Stake',
      earn: 'Earn',
      provide: 'Provide',
      withdraw: 'Withdraw',
      unstake: 'Unstake',
      leaderboard: 'Leaderboard',
      governance: 'Governance',
      other: 'Other',
      development: 'Development',
      launchpad: 'Launchpad',
      vesting: 'Vesting',
      buy_crypto: 'Buy Crypto',
      buy_tokens: 'Buy Tokens',
      wallet: 'Wallet',
      claim: 'Claim',
      competition: 'Competition',
    },
  },
};
