import { FC } from 'react';
import clsx from 'clsx';

export const LoaderRocket: FC<{ small?: boolean }> = ({ small }) => {
  return (
    <>
      <div className={clsx('loader-wrapper', small && 'relative')}>
        <div className={clsx('lds-circle', small && 'h-full w-full')}>
          {/* <img
            src="/assets/gif/loader.gif"
            alt="loading rocket"
            className={clsx(
              'loader',
              small && '!h-full max-h-[240px] !w-full max-w-[240px]',
            )}
          /> */}
        </div>
      </div>
    </>
  );
};
