export const NATIVE_TOKEN_DECIMALS = 6;
export const LP_TOKEN_DECIMALS = 6;
export const COMMISSION_RATE = 0.02; // 2%

export const classicNetwork = {
  name: 'classic',
  chainID: 'columbus-5',
  lcd: 'https://terra-classic-fcd.publicnode.com',
  fcd: 'https://terra-classic-fcd.publicnode.com',
  explorer: 'https://finder.terraport.finance/mainnet/',
  gasPrices: { uusd: 0.15 },
  fee: { gasPrice: 0.15, amount: 100000 }, // 0.1 USTC
  tokenContract: 'terra1ex0hjv3wurhj4wgup4jzlzaqj4av6xqd8le4etml7rg9rs207y4s8cdvrp',
  dispenserContract: 'terra1ksufhlwjcdd64nnzmanmgjs9p2vskrv6wlmj2fe0kkznd2fjfcssdrp644',
};
