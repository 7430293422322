import { PropsWithChildren } from 'react';
import { Logo } from 'ui/components/logo';
import { Button } from 'ui/components/button';
import { openWalletConnectModal, walletState } from 'state/wallet-state';
import { FaCheckCircle } from 'react-icons/fa';

const ConnectWalletButton = ({ label = 'WALLET' }: { label?: string }) => (
  <Button
    className="center gap-2 bg-gradient-to-t from-gr1 via-gr2 to-gr2 !text-white"
    onClick={openWalletConnectModal}>
    {walletState.use.address() && <FaCheckCircle className="text-theme-green" />}
    {label}
  </Button>
);

export const Header = ({ children }: PropsWithChildren) => {
  return (
    <>
      <div className="sticky top-0 z-10 flex w-dvw animate-fade-down flex-row flex-wrap items-center justify-between">
        <div className="grid h-fit w-full grid-cols-[auto_1fr_auto] items-center justify-between gap-2 bg-background3 p-4 px-4 !text-white md:grid-cols-[auto_1fr_auto] md:px-8 lg:grid-cols-[2fr_2fr_1fr_1fr]">
          <Logo className="mr-auto animate-fade animate-delay-75" />
          <div />
          <div />
          <div className="ml-auto flex animate-fade gap-2 self-end animate-delay-200 md:gap-4">
            <ConnectWalletButton />
          </div>
        </div>
        {children}
      </div>
    </>
  );
};
