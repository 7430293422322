import { FC, ReactNode, useEffect, useMemo } from 'react';
import { WalletModal } from '../../ui/modals/wallet-modal';
import { useGasPrice, useTaxRate } from '../../api/api';
import { useGetMeQuery } from 'api/terraport/base';
import { useSearchParams } from 'react-router-dom';
import { postReferral } from 'api/terraport/quests';

export const WalletConnectProvider: FC<{ children: ReactNode }> = ({ children }) => {
  useTaxRate();
  useGasPrice();
  const { data } = useGetMeQuery();
  const [params, setParam] = useSearchParams();
  const referrer = useMemo(() => params.get('referrer'), [params]);

  useEffect(() => {
    if (data?.address && referrer && data?.address !== referrer) {
      postReferral(data?.address, referrer);
      params.delete('referrer');
      setParam(params);
    }
  }, [data, params, referrer, setParam]);
  return (
    <>
      <WalletModal />
      {children}
    </>
  );
};
